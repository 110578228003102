/* line 4, /home/andreas/Projects/fyes/cloud/fybro/mobile/app/styles/main.scss */
body {
  font-family: Georgia, serif;
  font-size: 16px;
  background: white;
  color: black; }

/* line 11, /home/andreas/Projects/fyes/cloud/fybro/mobile/app/styles/main.scss */
h1 {
  font-size: 2em;
  margin: 0.5em 0 1em; }
  /* line 15, /home/andreas/Projects/fyes/cloud/fybro/mobile/app/styles/main.scss */
  h1 > small {
    color: gray;
    font-size: 70%; }

/* line 21, /home/andreas/Projects/fyes/cloud/fybro/mobile/app/styles/main.scss */
.hidden {
  display: none; }

/* line 23, /home/andreas/Projects/fyes/cloud/fybro/mobile/app/styles/main.scss */
.err {
  border: 1px solid #FF0000;
  color: #FF0000;
  display: none;
  margin: 10px 0;
  padding: 5px 7px; }

/* line 33, /home/andreas/Projects/fyes/cloud/fybro/mobile/app/styles/main.scss */
.w40 {
  width: 40px; }

/* line 36, /home/andreas/Projects/fyes/cloud/fybro/mobile/app/styles/main.scss */
.set-temp-down,
.set-temp-up,
.set-temp-forward,
.set-temp-back,
.set-dimmer-down,
.set-dimmer-up,
.set-dimmer-forward,
.set-dimmer-back {
  height: 50px;
  width: 50px; }

/* line 48, /home/andreas/Projects/fyes/cloud/fybro/mobile/app/styles/main.scss */
.set-temp-button,
.set-dimmer-button {
  margin-top: 60px; }

/* line 54, /home/andreas/Projects/fyes/cloud/fybro/mobile/app/styles/main.scss */
.space_val {
  background-color: #000;
  color: #0f0;
  font-weight: bold;
  padding: 2px;
  position: absolute;
  vertical-align: super;
  white-space: nowrap; }

/* line 65, /home/andreas/Projects/fyes/cloud/fybro/mobile/app/styles/main.scss */
#div-sel-space {
  margin-top: 20px; }

/* line 70, /home/andreas/Projects/fyes/cloud/fybro/mobile/app/styles/main.scss */
.cmd_default {
  background-color: #fff;
  color: #7F7F7F;
  display: block;
  font-size: 1.1em;
  height: 30px;
  padding-top: 3px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  width: 74px; }

/*
 * 
 * 
 * Please wait 
 * 
 * 
 */
/* Start by setting display:none to make this hidden.
   Then we position it in relation to the viewport window
   with position:fixed. Width, height, top and left speak
   for themselves. Background we set to 80% white with
   our animation centered, and no-repeating */
/* line 102, /home/andreas/Projects/fyes/cloud/fybro/mobile/app/styles/main.scss */
.wmodal {
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.8) url("Preloader_8.gif") 50% 50% no-repeat; }

/* When the body has the loading class, we turn
   the scrollbar off with overflow:hidden */
/* line 118, /home/andreas/Projects/fyes/cloud/fybro/mobile/app/styles/main.scss */
body.loading {
  overflow: hidden; }

/* Anytime the body has the loading class, our
   modal element will be visible */
/* line 124, /home/andreas/Projects/fyes/cloud/fybro/mobile/app/styles/main.scss */
body.loading .wmodal {
  display: block; }
